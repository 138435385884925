import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Divider, Typography, IconButton, Drawer, Button } from '@mui/material';
import Colors from 'src/styles/Colors';
import PropTypes from 'prop-types';  
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { setUser, setToken } from 'src/store/actions/authActions';
import useResponsive from 'src/hooks/useResponsive'; // Import the custom hook
import { ScrollingBox } from 'src/styles/CustomStyles';
import menuItems from './MenuItems';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import { AssignmentIndOutlined } from '@mui/icons-material';
const Sidebar = ({ role, isOpen, onToggle }) => {
  const location = useLocation();
  const isMobile = useResponsive(); // Use the custom hook

  const [isSidebarOpen, setIsSidebarOpen] = useState(isOpen);

  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(isOpen); // Collapse the sidebar on mobile
    } else {
      setIsSidebarOpen(isOpen); // Keep the sidebar open on larger screens
    }
  }, [isMobile, isOpen]);



  const dispatch = useDispatch();

  const handleLogout = () => {
    console.log("logout start")
   
    dispatch(setUser(null));
    dispatch(setToken(null));
    console.log("logout end")
    //window.location.href = '/login';
  };

  const drawerContent = (
    <ScrollingBox  sx={{
        padding: isMobile ? '0px' : '10px',
        paddingBottom: '0px !important',
        height: isMobile ? "100%":"100%",
        background: isMobile ? Colors.DARK_BLUE_2: "transparent",
      }}>
        <Box
        sx={{
        padding:  isMobile ? "20px":'10px',
        mb:1,
        display: 'flex',
        height: isMobile ? "95%": "90%",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: isMobile ? "0px":"16px",
        background: Colors.DARK_BLUE_2,
      }}
    >
      {/* Logo Section */}
      <Box sx={{ position: "relative", mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img 
            src="/logo-white.png" 
            alt="Logo" 
            style={{ width: '150px', maxWidth:'80%' }} 
        />
      </Box>

      {/* Menu Items Section */}
      <ScrollingBox sx={{ flexGrow: 1, overflowY: 'auto', width:"100% "}}>
        <Typography variant="body2" color={Colors.MEDIUM_GREY} sx={{ mb: 2 }}>MENU</Typography>
        {menuItems[role]?.map((item) => (
          <Link
            key={item.name}
            to={item.path}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color:"#d6d6d6",
              marginBottom: '10px',
              padding: '5px',
            }}
            
            >
            {
                item.key === 'results' ? 
                    <TroubleshootOutlinedIcon style={{ color: location.pathname.startsWith(item.path) ? Colors.AQUA : '#d6d6d6',width: '18x', marginRight: '10px' }}/>
                    :
                    item.key === 'overview' ? 
                        <RadarOutlinedIcon style={{ color: location.pathname.startsWith(item.path) ? Colors.AQUA : '#d6d6d6',width: '18px', marginRight: '10px' }}/>
                        :
                        item.key === 'surveys' ? 
                            <ChecklistOutlinedIcon style={{ color: location.pathname.startsWith(item.path) ? Colors.AQUA : '#d6d6d6',width: '18px', marginRight: '10px' }}/>
                            :
                            item.key === 'teams' ? 
                                <Groups2OutlinedIcon style={{ color: location.pathname.startsWith(item.path) ? Colors.AQUA : '#d6d6d6',width: '18px', marginRight: '10px' }}/>
                                :
                                item.key === 'accountManagers' ? 
                                    <AssignmentIndOutlined style={{ color: location.pathname.startsWith(item.path) ? Colors.AQUA : '#d6d6d6',width: '18px', marginRight: '10px' }}/>
                                    :
                                    <LeaderboardOutlinedIcon style={{ color: location.pathname.startsWith(item.path) ? Colors.AQUA : '#d6d6d6',width: '18px', marginRight: '10px' }}/>
                            
                      /*   <img src={`/${item.icon}`} alt="Icon" style={{ width: '16px', marginRight: '10px' }} /> */
            }
            <Typography variant="body2" sx={{
                color: location.pathname.startsWith(item.path) ? '#fff' : '#d6d6d6',
                  fontWeight: location.pathname.startsWith(item.path) ? 600 : 500,
            }}>{item.name}</Typography>
          </Link>
        ))}
        <Divider color={Colors.AQUA} />
        <Typography variant="body2" color={Colors.MEDIUM_GREY} sx={{ mb: 2, mt: 2 }}>AUTRE</Typography>
       {/*  ${role}- */}
        <Link
          key={`${role}-profil`}
          to={`/${role}/profil`}
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: location.pathname === `/${role}/profil` ? Colors.GREEN : 'white',
            marginBottom: '10px',
            padding: '5px',
          }}
        >
          <PersonOutlineOutlinedIcon style={{ marginRight: '10px' }} sx={{ fontSize: 20 }} />
          <Typography variant="body2">Profil</Typography>
        </Link>
        <Link
          key="logout"
          onClick={handleLogout}
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: location.pathname === '/admin/profile' ? Colors.GREEN : 'white',
            marginBottom: '10px',
            padding: '5px',
          }}
        >
          <LogoutOutlinedIcon style={{ marginRight: '10px' }} sx={{ fontSize: 20 }} />
          <Typography variant="body2">Déconnexion</Typography>
        </Link>
      </ScrollingBox>
      
      {/* Support and Contact Section */}
      <Box
        sx={{
          mt: 'auto',
          p: 2,
          backgroundColor: Colors.DARK_GREEN,
          borderRadius: '16px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-30px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '70px',
            height: '48px',
            backgroundImage: "url('/help.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Typography
          variant="body1"
          sx={{ color: 'white', mb: 1, mt: 1 }}
        >
          {"Besoin d'aide"}
        </Typography>
        <Button
          variant="contained"
        
          component={Link}
          to={`/${role}/contact`}
          sx={{
            backgroundColor: Colors.GREEN,
            color: 'white',
            borderRadius: '16px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#3FB4B1',
            },
          }}
        >
          Contactez-nous
        </Button>
      </Box>
    </Box>
   
        {/* Sage Partner Section */}
    {/* <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="/sage_logo.png" alt="Logo" style={{ width: '140px' }} />
    </Box> */}
    </ScrollingBox>
    
  );

  return (
    <Drawer
      variant={isMobile ? "temporary" : "persistent"}
      open={isSidebarOpen}
      onClose={isMobile ? onToggle : onToggle}
      sx={{
        '& .MuiDrawer-paper': {
          width: isSidebarOpen ? (isMobile ? "80%":"250px"):"0px",
          boxSizing: 'border-box',
          borderRightWidth:"0px !important",
          backgroundColor: "transparent",
          position: isMobile ? 'absolute' : 'relative',
          top: 0,
          right: 0,
        },
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

Sidebar.propTypes = {
  role: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Sidebar;

// src/components/Modals/CollaboratorsModal.js
import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, CircularProgress, Button, Chip, Avatar } from '@mui/material';
import { API_URL } from 'src/config';
import useAxios from 'src/services/apiInterceptor';
import Colors from 'src/styles/Colors';
import { CloseOutlined, Groups2Outlined } from '@mui/icons-material';
import { ScrollingDialogContent } from 'src/styles/CustomStyles';

const ShowCollaboratorsModal = ({ open, onClose, teamId }) => {
  const api = useAxios();
  const [collaborators, setCollaborators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (teamId && open) {
      setLoading(true);
      api.get(`${API_URL}/teams/collaborators/${teamId}`)
        .then(response => {
          setCollaborators(response.data);
        })
        .catch(error => {
          console.error("Failed to fetch collaborators:", error);
          setError('Failed to load collaborators');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [teamId, open]);

  const getStatusColor = (status) => {
    switch(status) {
      case 'actif':
        return Colors.TOTALEMENT;
      case 'inactif':
        return Colors.PEU;
      default:
        return Colors.TOTALEMENT; // Replace with your default color if needed
    }
  };

  return (
    <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" fullWidth
        style={{}}
    >
      <DialogTitle
            sx={{
                position: 'relative',
                display:"flex",
                alignItems:"center",
                gap:"5px",
                flexWrap:"wrap"
            }}
      >
        <Groups2Outlined 
            sx={{
                color:Colors.MEDIUM_GREEN
            }}
            fontSize="large"
        />
            
            Collaborateurs du entreprise 
            
            <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
                <CloseOutlined />
          </IconButton>
        </DialogTitle>
      <ScrollingDialogContent
      sx={{maxHeight:"350px"}}
      >
        <Table 
          stickyHeader 
            sx={{ 
                    height:"400px",
                    overflow:"auto",
                    background: "#fff",
                    borderRadius: "15px" 
                }}
        >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Profil</TableCell>
                <TableCell>Prénom</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Etat</TableCell>
                <TableCell>Crée le</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 250 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow style={{ height: 250 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Error: {error}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                collaborators.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>
                      <Avatar 
                        src={user.profile_picture ? user.profile_picture : '/user_profile.png'}
                        alt={user.first_name}
                      />
                    </TableCell>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Chip
                        label={user.status ? user.status.charAt(0).toUpperCase() + user.status.slice(1) : 'Non défini'}
                        sx={{ backgroundColor: getStatusColor(user.status), color: '#fff' }}
                      />
                    </TableCell>
                   
                    <TableCell>
                        {new Date(user.created_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                     
                    
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
      </ScrollingDialogContent>
    </Dialog>
  );
};

export default ShowCollaboratorsModal;

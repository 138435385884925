import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import PropTypes from 'prop-types';
import useResponsive from 'src/hooks/useResponsive';
import Colors from 'src/styles/Colors';

const Layout = ({ role }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = useResponsive();

  useEffect(()=>{
    if(isMobile){
        setSidebarOpen(false);
    }else{
        setSidebarOpen(true);
    }
  },[isMobile]);
  
  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: 'flex', background: "#F5F9FF", overflowY: 'hidden', height: "100vh" }}>
      <Sidebar role={role} isOpen={isSidebarOpen} onToggle={handleSidebarToggle} />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '0px' }}>
        <Navbar onSidebarToggle={handleSidebarToggle} />
        <Box sx={{
          flexGrow: 1,
          display: 'flex', flexDirection: 'column',
          height: "calc(100vh - 120px)",
          overflowY: "auto",
          maxWidth: isMobile ? '100vw' : (isSidebarOpen ? 'calc(100vw - 280px)' : "100vw"),
          width: isMobile ? '100vw' :  (isSidebarOpen ? 'calc(100vw - 280px)' : "100vw")
        }}>
          <Outlet /> {/* This is where nested routes will render */}
        </Box>
        <Box 
            sx={{
                mt:"5px",
                height:"30px",
                borderTop: "solid 1px "+Colors.GREEN,
                px: 2,
                display: "flex",
                justifyContent:"end",
                flexWrap: "wrap",
                alignItems:"center"
            }}
        >

            <Typography sx={{fontSize:"12px", fontWeight:"bold"}}>Copyright Issho Partners 2024. Tous droits réservés.</Typography>
           
        </Box>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  role: PropTypes.string.isRequired,
};

export default Layout;

/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, CircularProgress, Tooltip } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { FormTextField, FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { API_URL } from 'src/config';
import useAxios from 'src/services/apiInterceptor';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { useDispatch } from 'react-redux';

const EditTeamModal = ({ open, onClose, onSubmit, teamData, setTeamData, errors, loading  }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTeamData({ ...teamData, [name]: value });
    };
    
    const api = useAxios();
    const fileInputReference = useRef(null);
    const dispatch = useDispatch();

    const [loadingUpload, setLoadingUpload] = useState(false);
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoadingUpload(true);
        
        const formData = new FormData();
        formData.append('file', file);

            try {
                const response = await api.post(`${API_URL}/teams/uploadUsersFile/${teamData.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                });

                // Handle response if needed
                console.log('File uploaded successfully:', response.data);
                // fileInputRef.current.value = null;
                dispatch(showSnackbar('Collaborateurs importés avec succés : '/* + response.data.message */, 'success'));
            
            } catch (error) {
                console.error("Erreur lors du téléversement du fichier:", error);
                if (error?.response?.data) {
                const validationErrors = error.response.data.error || {}; // Adjust based on your API response structure
                
                dispatch(showSnackbar('Erreur lors du téléversement du fichier'+ validationErrors, 'error'));
                } else {
                dispatch(showSnackbar('Erreur lors du téléversement du fichier'+ error.message, 'error'));
                }
                
            } finally {
                setLoadingUpload(false);
                fileInputReference.current.value = null;

            }
        }
    };

    const handleUploadClick = () => {
        fileInputReference.current.click();
    };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ position: 'relative',  py: 1, display: 'flex', alignItems: 'center' }}>
          <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
          <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
            Modifier un équipe
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormTextField
          autoFocus
          margin="dense"
          label="Nom"
          name="name"
          fullWidth
          variant="outlined"
          value={teamData.name}
          onChange={handleInputChange}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
        <FormTextField
          margin="dense"
          label="Département"
          name="department"
          fullWidth
          variant="outlined"
          value={teamData.department}
          onChange={handleInputChange}
          error={Boolean(errors.department)}
          helperText={errors.department}
        />
        <FormTextField
          margin="dense"
          label="Max Collaborateurs"
          name="max_collaborators"
          type="number"
          fullWidth
          variant="outlined"
          value={teamData.max_collaborators}
          onChange={handleInputChange}
          error={Boolean(errors.max_collaborators)}
          helperText={errors.max_collaborators}
              />
        {/* Hidden file input */}
        <input
            type="file"
            ref={fileInputReference}
            style={{ display: 'none' }}
            accept=".csv" // Ensure only CSV files are selected
            onChange={handleFileUpload}
        />
      </DialogContent>
      <DialogActions
        sx={{ px: 2, py: 1 }}
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}
      >
            <Tooltip title="Importer un fichier csv : first_name,last_name,email \n John,Doe,john.doe@example.com" arrow>
                
                <FormButtonCancel
                onClick={handleUploadClick}
                variant="outlined"
                  >
                     {loadingUpload ? (
                            <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                          ) : (
                            <UploadFileOutlinedIcon sx={{ mr: 1 }} />
                          )}
                    Importer des Collaborateurs
                </FormButtonCancel>
            </Tooltip>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
        >
          <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
          <FormButton onClick={onSubmit} disabled={loading}>
            {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />  : null } Modifier
          </FormButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditTeamModal;

import { Box, Typography } from "@mui/material";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip } from "recharts";
import Colors from "src/styles/Colors";

const GlobalRadarChartComponent = ({ sectionScores }) => {
    // Prepare the data for the radar chart
    console.log(sectionScores)
    const data = sectionScores.map(section => ({
        name: section.name,
        score: Math.round(section.average_score *100)/100 // Convert to percentage
    }));
    console.log(data)

    return (
        <Box sx={{ padding:0, display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <RadarChart outerRadius="80%" width={400} height={350} data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="name" tick={<CustomTick />} />
                <PolarRadiusAxis angle={270} domain={[1, 6]} />
                <Radar 
                name="Score"
                fontSize={"14px"} 
                dataKey="score" 
                dot={true}
                strokeWidth="2px" 
                stroke={Colors.DARK_BLUE} 
                fill={Colors.LIGHT_BLUE}  fillOpacity={0.6} />
                <Tooltip 
                    contentStyle={{ fontSize: '12px' }}  // Additional styling for tooltip content
                    fontSize={"12px"}
                />
                <Legend 
                wrapperStyle={{ fontSize: '12px' }}  
                fontSize={"12px"}/>
            </RadarChart>
        </Box>
    );
};

// Custom tick component
const CustomTick = ({ x, y, payload }) => (
    <text x={x} y={y} textAnchor="middle" fill="#666" fontSize="10px" fontWeight={"bold"}>
      {payload.value}
    </text>
);

export default GlobalRadarChartComponent;
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import useAxios from 'src/services/apiInterceptor';
import { API_URL } from 'src/config';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { FormButtonCancel } from 'src/styles/CustomStyles';
import { FormButton } from 'src/styles/CustomStyles';
import { CloseOutlined, LockPersonOutlined } from '@mui/icons-material';
import Colors from 'src/styles/Colors';

const SetCollaboratorStatus = ({ collaborator, onClose, onStatusChange }) => {
    const api = useAxios();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleChangeStatus = async () => {
        setLoading(true);
        try {
            await api.put(`/teams/collaborators/${collaborator.id}/status`, { status: collaborator.status  });
            dispatch(showSnackbar(`Le compte de l'Collaborateur a été ${(collaborator.status === "active" ||  collaborator.status === null)? 'désactivé' : 'activé'} avec succès`, 'success'));
            onStatusChange();
        } catch (error) {
            dispatch(showSnackbar("Échec du changement de statut", 'error'));
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <Dialog 
            open 
            onClose={onClose}
            maxWidth="md"  // Control the max width of the dialog
            fullWidth      // Ensure the dialog takes up the full width based on maxWidth
            PaperProps={{
                sx: { width: '600px', minHeight: '280px', maxHeight: '90vh' },  // Customize the paper's width and height
            }}

        >
            <DialogTitle sx={{position:"relative", display:"flex", alignItems:"center", justifyContent:"start", gap:"5px"}}>
                <LockPersonOutlined sx={{color: Colors.MEDIUM_GREEN}}/>
                {(collaborator.status === 'active' || collaborator.status === null) ? "Désactiver" : "Activer"} le compte de l'Collaborateur <span style={{color: Colors.LIGHT_BLUE}}> {collaborator?.first_name} {collaborator?.last_name}</span> 
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                        <CloseOutlined />
                </IconButton>    
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{mt:2}}>
                    Êtes-vous sûr de vouloir {(collaborator.status === 'active' || collaborator.status === null)  ? "désactiver" : "activer"} le compte de l'Collaborateur <span style={{color: Colors.LIGHT_BLUE}}> {collaborator?.first_name} {collaborator?.last_name}</span>?
                </Typography>
                <Typography variant="body1" sx={{mt:2}}>
                    Une fois {(collaborator.status === 'active' || collaborator.status === null)  ? "désactivé, l'Collaborateur ne pourra plus accéder à son compte. " : "activé, il pourra se connecter à nouveau à son compte."}
                </Typography>
            </DialogContent>
            <DialogActions>
                <FormButtonCancel onClick={onClose} color="secondary">Annuler</FormButtonCancel>
                <FormButton onClick={handleChangeStatus} color="primary" disabled={loading}>
                    {loading ? 'Changement...' : 'Confirmer'}
                </FormButton>
            </DialogActions>
        </Dialog>
    );
};

export default SetCollaboratorStatus;

import React, { useEffect } from 'react';
import {Grid, Card, CardContent, Typography, Button, Box, LinearProgress, Chip, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import Colors from 'src/styles/Colors';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardStats } from 'src/store/actions/statsActions';

const DashboardWidget = ({ role }) => {

const dispatch = useDispatch();

const {
    totalDiagsInprogress,
    totalDiagsFinished,
    totalTeamsDiags,
    totalManagedTeams,
    totalManagedCollaborators,
    totalTeamsDiagsList,
    loading,
    error
} = useSelector((state) => state.stats);

    useEffect(() => {
        dispatch(fetchDashboardStats());
    }, [dispatch]);

const navigate = useNavigate();
// Fetching Data Based on Role
useEffect(() => {
    if (role === 'admin') {
      // Fetch admin data
    } else if (role === 'manager') {
      // Fetch manager data
    }
  }, [role]);
  
  return (
    <div style={{ display:'flex', flexDirection:'column',margin: 0, padding: '0 16px' }}>
      <Typography variant="h6" color={Colors.DARK_BLUE}>Tableau de bord</Typography>
  
            {role === 'admin' ? (
                console.log("Admin")
            ) : (
                  // Render manager-specific content
                console.log("Oussama manager")
                  
            )}

      <Grid container spacing={2}>
        {/* 1st Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: Colors.DARK_GREEN,
              position: 'relative',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', backgroundColor: Colors.GREEN, borderRadius: "8px" }}>
                  <GroupsOutlinedIcon fontSize="medium" style={{ color: "#fff" }} />
                </div>
              </div>
              <Typography variant="h4" component="div" color="#fff" sx={{ mt: 3, mb: 1 }}>
              {totalDiagsInprogress || 0}
              </Typography>
              <Typography variant="h6" component="div" style={{ lineHeight: '1.2' }} gutterBottom color="#fff">
                Nombre de Diagnostics en cours
              </Typography>
            </CardContent>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '50px',
                height: '100px',
                backgroundImage: "url('/network-vector.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 1,
              }}
            />
          </Card>
        </Grid>

        {/* 2nd Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: Colors.DARK_BLUE,
              position: 'relative',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', backgroundColor: Colors.VERY_DARK_BLUE, borderRadius: "8px" }}>
                  <PlaylistAddCheckOutlinedIcon fontSize="medium" style={{ color: "#fff" }} />
                </div>
              </div>
              <Typography variant="h4" component="div" color="#fff" sx={{ mt: 3, mb: 1 }}>
                {totalDiagsFinished || 0}
              </Typography>
              <Typography variant="h6" component="div" style={{ lineHeight: '1.2' }} gutterBottom color="#fff">
                Nombre de Diagnostics Terminés
              </Typography>
            </CardContent>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '50px',
                height: '100px',
                backgroundImage: "url('/network-vector.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 1,
              }}
            />
          </Card>
        </Grid>

        {/* 3rd Column */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: 2,
            }}
          >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
             {/*  <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                <Card sx={{ height: '100%', boxShadow: 2, position: 'relative' }}>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      Equipes en diagnostic
                    </Typography>
                    <Typography variant="h4" component="div" color="secondary" sx={{ mt: 2, lineHeight: 1, fontWeight: 800, color: '#334A52' }}>
                       { totalTeamsDiags || 0 } <small>de</small> {totalManagedTeams}
    
                    </Typography>
                    <Typography sx={{ color: Colors.LIGHT_GREY, fontSize: '0.6rem' }} variant="caption" component="div">
                      <span style={{ color: Colors.GREEN }}>
                        { totalTeamsDiags && totalManagedTeams && totalManagedTeams !== 0 
                            ?
                            Math.round((totalTeamsDiags * 100 / totalManagedTeams)*100)/100 : 0
                        }
                        </span> % par rapport au Mois précédent
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: '5px',
                      width: '40px',
                      height: '40px',
                      backgroundImage: "url('/chart-up.png')",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      zIndex: 1,
                    }}
                  />
                </Card>
              </Grid> */}
              <Grid item xs={12} sm={12} sx={{ flex: 1 }}>
                <Card sx={{ height: '100%', boxShadow: 2, position: 'relative' }}>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      Collaborateurs
                    </Typography>
                    <Typography variant="h4" component="div" color="secondary" sx={{ mt: 2, lineHeight: 1, fontWeight: 800, color: '#334A52' }}>
                    { totalManagedCollaborators || 0}
                    </Typography>
                    <Typography sx={{ color: Colors.LIGHT_GREY, fontSize: '0.6rem' }} variant="caption" component="div">
                      <span style={{ color: Colors.GREEN }}>+11%</span> par rapport au Mois précédent
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: '5px',
                      width: '40px',
                      height: '40px',
                      backgroundImage: "url('/chart-up.png')",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      zIndex: 1,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: Colors.GREEN }}
              fullWidth
              onClick={()=> navigate('/manager/equipes')}
              startIcon={<AddOutlinedIcon fontSize='medium' />}
              sx={{
                fontSize: 16,
                fontWeight: 600,
                textTransform: 'none'
              }}
            >
              Ajouter un collaborateur
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mt: 2 }} color={Colors.DARK_BLUE}>Collaborateurs en cours de diagnostic</Typography>

      {/* New Card */}
      <Card sx={{ mt: 2, flexGrow:1}}>
        <CardContent>
        <div>
            <Typography variant="h6" gutterBottom>
                Collaborateurs en diagnostic 
                    <span style={{color: Colors.GREEN}}> {totalTeamsDiagsList?.length || 0}</span>
            </Typography>
            <TableContainer component={Paper}  sx={{ minHeight: '400px' }}>
                <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className="center-align">ID</TableCell>
                            <TableCell className="center-align">Nom</TableCell>
                            <TableCell className="center-align">Equipe</TableCell>
                            <TableCell className="center-align">Enquête</TableCell>
                            <TableCell className="center-align">Etat</TableCell>
                            <TableCell className="center-align">Progression</TableCell>
                            <TableCell className="center-align">Crée le</TableCell>
                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow style={{ height: 270 }}>
                                <TableCell colSpan={9} align="center">
                                    <Typography>Loading...</Typography>
                                </TableCell>
                            </TableRow>
                        ) : error ? (
                            <TableRow style={{ height: 270 }}>
                                <TableCell colSpan={9} align="center">
                                    <Typography>Error: {error}</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            totalTeamsDiagsList.map((item) => (
                                <TableRow key={item.id} 
                                    sx={{
                                        textAlign: 'center'
                                }}>
                                    <TableCell className="center-align">{item.id}</TableCell>
                                    <TableCell className="center-align">{item.user_first_name} {item.user_last_name}</TableCell>
                                    <TableCell className="center-align">{item.user_role === "manager" ? "Manager" : item.user_entreprise }</TableCell>
                                    <TableCell className="center-align">{item.survey_name}</TableCell>
                                    <TableCell className="center-align">
                                        <Chip 
                                            sx={{ 
                                                color: "#fff",
                                                height:"24px",
                                                backgroundColor: item.status === "in_progress" ? 
                                                    Colors.ORANGE :
                                                    Colors.GREEN
                                            }}
                                            label={
                                                item.status === "in_progress" ? 
                                                "En progression" :
                                                "Terminé"
                                            }
                                        />
                                    </TableCell>
                                    <TableCell className="center-align">
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection:"column",
                                            justifyContent:"center",
                                            alignItems: 'center'
                                        }}>
                                            <Box sx={{ width: '100%', mr: 1 }}>
                                                <LinearProgress
                                                    value={item.questions_choices_count > item.total_questions ? 100 : item.questions_choices_count / item.total_questions * 100}
                                                    variant="determinate"
                                                    sx={{
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: Colors.LIGHT_BLUE,
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: Colors.GREEN,
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ minWidth: 35 }}>
                                                <Typography variant="body2" color="text.secondary">{`${item.questions_choices_count >item.total_questions ? 100:  Math.round(
                                                item.questions_choices_count / item.total_questions * 100,
                                                )}%`}</Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="center-align">
                                        {new Date(item.created_at).toLocaleString('fr-FR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </TableCell>
                                    <TableCell className="center-align">
                                        {new Date(item.updated_at).toLocaleString('fr-FR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </TableCell>
                                  
                                </TableRow>
                            ))
                        )}
                        {totalTeamsDiagsList.length === 0 && (
                            <TableRow style={{ height: 270 }}>
                                <TableCell colSpan={9} align="center">
                                    <Typography>Aucune Donnée</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        </CardContent>
      </Card>
    </div>
  );
};
DashboardWidget.propTypes = {
    role: PropTypes.string.isRequired,  // Specify that role is a required string
  };
export default DashboardWidget;

// SurveysCounter.js

import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';
import useAxios from 'src/services/apiInterceptor';
import Colors from 'src/styles/Colors';
import { SmallLightText } from 'src/styles/CustomStyles';
import { CheckBoxOutlined } from '@mui/icons-material';

const SurveyTeamsFinishedCounter = () => {
    const [surveyCount, setSurveyCount] = useState(0);
   /*  const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); */
    const api = useAxios();
    useEffect(() => {
        // Fetch the survey count from the API
        const fetchSurveyCount = async () => {
            try {
                const response = await api.get('/surveys/manager/teams-surveys/finished/count');
                setSurveyCount(response.data.count);
               /*  setLoading(false); */
            } catch (err) {
                console.log(err)
                /* setError(err);
                setLoading(false); */
            }
        };

        fetchSurveyCount();
    }, []);

  /*   if (loading) return <Typography>Loading...</Typography>; */
    /* if (error) return <Typography color="error"></Typography>; */

    return (
        <Card
        sx={{
            borderRadius: 4,
            boxShadow: /* isActive ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : */ 2, 
            backgroundColor: Colors.TITLE_1,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
        }}
    >
        <CardContent>
        <div style={{ display: 'flex', justifyContent: 'flex-start',alignItems: 'center', gap:"15px"}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', backgroundColor: "#fff", borderRadius: "8px" }}>
                    <CheckBoxOutlined fontSize="medium" style={{ color: "#999" }} />
                </div>
                <Typography variant="h4" component="div" color="#fff" sx={{ mt: 1, mb: 1 }}>
                    {surveyCount} <SmallLightText>collaborateurs</SmallLightText>
                </Typography>
        </div>
        
        <Typography variant="h6" component="div" style={{ lineHeight: '1.2' }} gutterBottom color="#fff">
            Diagnostics terminés
        </Typography>
        </CardContent>
        <Box
        sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '50px',
            height: '100px',
            backgroundImage: "url('/network-vector.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 1,
        }}
        />
    </Card>
    );
};

export default SurveyTeamsFinishedCounter;

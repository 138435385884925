
const Colors = {
  DARK_BLUE: '#134093',
  LIGHT_BLUE: '#69a0b8',
  AQUA: '#00d5cb',
  DARK_BLUE_2: "#132c5b",
  VERY_DARK_BLUE: "#1b1457",
  VERY_DARK_BLUE_2: "#132c5b",
  LIGHT_GREEN:"#f3f3f3",
  GREEN: "#000",
  DARK_GREEN: "#132c5b",
 
  MEDIUM_GREEN: "#132c5b",
  ORANGE: "#ffa162",

  MEDIUM_GREY:"#AFC6C4",
  LIGHT_GREY: '#D9D9D9',
  VERY_LIGHT_GREY: '#F3F3F3',
  PINK_GREY: " #E7D4CF",
  TITLE_1: "#454545",
  TITLE_2: "#323332",
  TITLE_3: "#211121",
    TEXT_BODY: "#454545",
    YELLOW: "#ffd402",
   
    PAS_DU_TOUT: "#FF4136",
    PEU: "#FF851B",
    PEU_2: "#FFDC00",
    MODEREMENT:"#0074D9",
    MODEREMENT_2:"#01ff8c", //01FF70
    TOTALEMENT:"#1ead3e"//2ECC40
  

  /*   --theme-background-color: #fff;
    --theme-forgraund-full: #000;
    --theme-background-color-hover: #f2f5f6;
    --text-primary: rgba(0, 0, 0, 0.9);
    --text-secondary: rgba(0, 0, 0, 0.65);
    --text-100-opacity: #000;
    --text-disabled: rgba(0, 0, 0, 0.3);
    --text-negative: #CD384B;
    --text-highlight-ai: linear-gradient(82deg, #13A038 -6.91%, #0092DB 37.65%, #8F49FE 67.88%);
    --sage-brilliant-green: #00D639;
    --theme-background-color-inverse: #000;
    --theme-background-color-inverse-hover: #1b1b1b;
    --text-primary-inverse: rgba(255, 255, 255, 0.9);
    --text-secondary-inverse: rgba(255, 255, 255, 0.65);
    --text-disabled-inverse: rgba(255, 255, 255, 0.3);
    --text-negative-inverse: #D86473;
    --button-default-color: #fff;
    --button-default-bg: #000;
    --button-default-color-hover: #fff;
    --button-default-bg-hover: #303030;
    --button-default-color-active: #fff;
    --button-default-bg-active: #5e5e5e;
    --button-disabled-color: rgba(0, 0, 0, 0.3);
    --button-disabled-bg: #e6ebed;
    --button-secondary-color: #000;
    --button-secondary-bg: transparent;
    --button-secondary-border: #000;
    --button-secondary-bg-hover: #e2e2e2;
    --button-secondary-bg-active: #f0f0f0;
    --button-link-color: rgba(0, 0, 0, 0.9);
    --button-link-underline: rgba(0, 0, 0, 0.9);
    --button-link-hover: rgba(0, 0, 0, 0.9);
    --button-link-hover-underline: #00D639;
    --button-link-active: rgba(0, 0, 0, 0.9);
    --button-link-active-bg: rgba(0, 0, 0, 0.04);
    --button-checkout-color: #fff;
    --button-checkout-bg: #008146;
    --button-checkout-bg-hover: #006738;
    --button-checkout-bg-active: #004D2A;
    --button-checkout-border-color: #008146;
    --button-checkout-border-bg: #fff;
    --button-checkout-border-border: #008146;
    --button-checkout-secondary-color: #008146;
    --button-checkout-secondary-bg-hover: #F3F8F6;
    --button-checkout-secondary-bg-active: #F7FCFA;
    --button-checkout-link-color: #008146;
    --button-checkout-link-underline: #008146;
    --button-checkout-link-hover: #006738;
    --button-checkout-link-hover-underline: #006738;
    --button-checkout-link-active: #004D2A; */
  
  
  };
  
  export default Colors;
  
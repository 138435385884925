/* eslint-disable no-unused-expressions */
import { BarChartOutlined, ChevronLeftOutlined, CloseOutlined } from "@mui/icons-material";
import {  Box, Card, CardContent, Chip, CircularProgress, Dialog,  DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import {  useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiagnosisResult } from "src/store/actions/resultDiagnosisActions";
import Colors from "src/styles/Colors";
import { FormButtonCancel } from "src/styles/CustomStyles";
import GlobalRadarChartComponent from "../components/GlobalRadarChart";
import { FormButton } from "src/styles/CustomStyles";
import { ScrollingDialogContent } from "src/styles/CustomStyles";
import {
    useGaugeState,
    Gauge,
    gaugeClasses,
  } from '@mui/x-charts/Gauge';
import { ScrollingBox } from "src/styles/CustomStyles";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "src/store/actions/snackbarActions";

const ResultsModal = ({ open, onClose, onSubmit, diagnosis, loading, isDialog}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { diagnosisResult, loading: resultLoading, error } = useSelector((state) => state.diagnosisResult);
    const { user } = useSelector((state) => state.auth);
    const [localResult, setLocalResult]= useState(null);
    const [contentLoaded, setContentLoaded] =useState(false)

    //const [expanded, setExpanded] = useState(true);
    // Create a ref for each section
    const sectionRefs = useRef([]);

    // Scroll to the section when clicked
    const handleScrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    };

/*     const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    }; */

    useEffect(() => {
        if (diagnosis) {
            console.log("From Diagnosis Results Fetch")
            console.log(diagnosis)
            dispatch(fetchDiagnosisResult(diagnosis.id));
            
        }
    }, [diagnosis, dispatch]);
  
    const pdfRef =useRef();
    const [loadingPdf,setloadingPdf] = useState(false);

    const handleDownloadPdf = async () => {
        const input = pdfRef.current;
        setloadingPdf(true);
    
        // Create a clone of the content
        const clonedNode = input.cloneNode(true);
        clonedNode.style.height = 'auto';
        clonedNode.style.maxHeight = 'none';
        clonedNode.style.overflow = 'visible';
        document.body.appendChild(clonedNode);
    
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
    
        // Define margins in mm
        const topMargin = 10; // Top margin in px
        const bottomMargin = 20; // Bottom margin in px
        const pxToMm = 0.264583; // 1 px = 0.264583 mm
        const topMarginMm = topMargin * pxToMm;
        const bottomMarginMm = bottomMargin * pxToMm;
        const contentHeight = pdfHeight - topMarginMm - bottomMarginMm;
    
        // Capture the overview content
        const overview = clonedNode.querySelector('.overview');
        if (overview) {
            const canvas = await html2canvas(overview, {
                scrollX: -window.scrollX,
                scrollY: -window.scrollY,
                useCORS: true,
                scale: 1.8,
            });
    
            const imgData = canvas.toDataURL('image/jpeg', 0.9);
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            pdf.addImage(imgData, 'JPEG', 0, topMarginMm, imgWidth, imgHeight);
        }
    
        // Capture section-brief content
        const sections = clonedNode.querySelectorAll('.section-brief');
        for (let i = 0; i < sections.length; i += 3) {
            //if (i > 0) {
                pdf.addPage();
            //}
    
            for (let j = i; j < i + 3 && j < sections.length; j++) {
                const section = sections[j];
    
                // Capture each section as a separate image
                const canvas = await html2canvas(section, {
                    scrollX: -window.scrollX,
                    scrollY: -window.scrollY,
                    useCORS: true,
                    scale: 1.8,
                });
    
                const imgData = canvas.toDataURL('image/jpeg', 0.9);
                const imgWidth = pdfWidth;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
                // Calculate vertical offset for placing images on the same page
                const position = topMarginMm + j % 3 * imgHeight;
                pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
            }
        }
    
        await pdf.save('rapport_de_synthese_diag-experts_' + new Date().getMilliseconds() + '.pdf');
    
        // Clean up the cloned node
        document.body.removeChild(clonedNode);
    
        setloadingPdf(false);
    };
    
    
    
    
    
    
    
    // Helper function to load an image
    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    };
    

    useEffect(() => {
        if (diagnosisResult) {
            console.log("From Diagnosis Results handle")
            console.log(diagnosisResult)
            let { totalScore, totalQuestions } = diagnosisResult.section_scores.reduce(
                (acc, section) => {
                  if (section.isAnswered) {
                    acc.totalScore += section.score;
                    acc.totalQuestions += section.total_questions;
                  }
                  return acc;
                },
                { totalScore: 0, totalQuestions: 0 }
              );
              
              let moyenneGenerale = totalQuestions > 0 ? totalScore / totalQuestions : 0;

              let recommandation = "";

                if (moyenneGenerale === 1) {
                    recommandation = "Votre entreprise se trouve dans une situation délicate, nécessitant des ajustements immédiats. Les processus internes doivent être révisés pour améliorer l'efficacité et la performance. Il est essentiel de se concentrer sur les améliorations pour éviter une détérioration de la situation.";
                } else if (moyenneGenerale > 1 && moyenneGenerale < 2) {
                    recommandation = "Votre entreprise est en train de stabiliser ses opérations. Bien que des bases aient été posées, des améliorations significatives sont encore nécessaires pour maximiser la performance. Investir dans l'optimisation des processus pourrait apporter des résultats tangibles.";
                } else if (moyenneGenerale >= 2 && moyenneGenerale < 3) {
                    recommandation = "Votre entreprise démontre un bon niveau de performance, avec des processus solides en place. Pour passer à la prochaine étape, continuez à affiner vos approches et à chercher des moyens d'innover dans vos opérations.";
                } else if (moyenneGenerale >= 3 && moyenneGenerale < 4) {
                    recommandation = "Votre entreprise se positionne favorablement sur le marché. Les stratégies et processus en place sont efficaces, mais il est crucial d'augmenter l'innovation pour se démarquer encore davantage de la concurrence.";
                } else if (moyenneGenerale >= 4 && moyenneGenerale < 5) {
                    recommandation = "Votre entreprise excelle dans ses opérations, montrant un niveau élevé de performance. Pour maintenir cette dynamique, continuez à chercher des opportunités d'amélioration et à développer vos compétences.";
                } else if (moyenneGenerale >= 5 && moyenneGenerale <= 6) {
                    recommandation = "Votre entreprise est un modèle d'excellence. Vous excellez dans tous les aspects, et votre défi consiste à maintenir cette position de leader tout en continuant à innover et à établir de nouvelles normes dans le secteur.";
                } else {
                    recommandation = "Il y a des incertitudes concernant votre score. Veuillez vérifier les données et réévaluer la situation.";
                }
            let result = { ...diagnosisResult, moyenne: Math.round(moyenneGenerale * 100) / 100, recommandation: recommandation };
 
            console.log("localvar result",result)
            setLocalResult(result)
            setContentLoaded(true)
            /* setExpanded(null) */


        }
    }, [diagnosisResult]);


    const getColor = (score, isAnswered) =>{
        if(isAnswered){
            if ( score === 1) {
                return Colors.PAS_DU_TOUT; // Low score
            } else if (score > 1 && score < 2) {
                return Colors.PEU;// Medium score
            } else if (score >= 2 && score < 3) {
                return Colors.PEU_2;// Medium score
            } else if (score>= 3 && score < 4) {
                return Colors.MODEREMENT; // Average score
            } else if (score >= 4 && score < 5) {
                return Colors.MODEREMENT_2;// Medium score
            } else if (score>= 5 && score <= 6) {
                return Colors.TOTALEMENT; // Good score
            } else {
                return Colors.TITLE_2; // Excellent score
            }
        }else{
            return Colors.TITLE_2;
        }
        
    }
    if(open && !contentLoaded) {
        return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
            <CircularProgress sx={{color: Colors.MEDIUM_GREEN}}/>
            <Typography variant="h6" sx={{ mt: 2, color: Colors.MEDIUM_GREEN }}>
            Chargement des données, veuillez patienter un moment ...
            </Typography>
        </Box>
        );
    }
    return (
      <Dialog
        sx={{
                bgColor: Colors.VERY_LIGHT_GREY,
            '& .MuiDialog-paper':
             { 
                maxWidth:isDialog ? '95vw !important' :'100vw !important',
                maxHeight:isDialog ? '97vh !important' :'100vh !important',
                margin: isDialog ? '32px' :'0px !important',
                width: '100vw',
                height:'100vh' } }}
        open={open} onClose={onClose}>
        <DialogTitle sx={{position: 'relative', backgroundColor: Colors.VERY_LIGHT_GREY,}}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent:"space-between",
                    alignItems: 'center' 
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center' }}>
                    <BarChartOutlined style={{ width: 24, height: 24, marginRight: 8, color: Colors.DARK_BLUE }} />
                    <Box>
                        <Box sx={{gap:"3px", display:"flex", justifyContent: "start", alignItems:"center", flexWrap:"wrap"}}>
                            <Typography variant="h5" color={Colors.TITLE_1} fontWeight={700}>
                                Synthèse et résultats
                            </Typography>
                            <Typography variant="h5" color={Colors.DARK_BLUE} fontWeight={700}>
                            {localResult ? ` ${localResult.survey_name}` : null}
                            </Typography>
                        </Box>
                       
                        <Typography variant="h5" color={Colors.DARK_BLUE} fontWeight={600}>
                         {localResult ? ` ${localResult.user_first_name} ${localResult.user_last_name} | ${localResult.user_company || null}` : null}
                        
                        </Typography>
                    </Box>
                </Box>
            
                <FormButton 
                
                    size="small" 
                    sx={{mr:1, minWidth:"100px"}} 
                    onClick={handleDownloadPdf}
                >
                    {loadingPdf ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.DARK_BLUE }} /> : null}
                    Imprimer en PDF
                </FormButton>
                
            
                {
                    isDialog ? 
                    (
                        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 2, right: 2 }}>
                        <CloseOutlined />
                        </IconButton>
                    ):
                    user.role === "contributor" ?

                    (
                        <FormButtonCancel 
                        onClick={()=>   navigate('/'+user?.role+'/questionnaire')} 
                        sx={{ }}>
                            <ChevronLeftOutlined sx={{mr:1}}/> Revenir à l'accueil
                        </FormButtonCancel>
                    )
                    :

                    (
                        <FormButtonCancel 
                        onClick={()=>   navigate('/'+user?.role+'/diagnostics/list')} 
                        sx={{ }}>
                        <ChevronLeftOutlined sx={{mr:1}}/> Liste des Diagnostics
                        </FormButtonCancel>
                    )
                }
               
            </Box>
        </DialogTitle>
        <ScrollingDialogContent
            ref={pdfRef}  
            sx={{
                padding:0,
                /* padding: isDialog ? null : 3, */
                background: Colors.VERY_LIGHT_GREY
            }}
        >
        {/* Other component content */}

        {localResult && (
            <Box>
                <Box className="overview">
                    <Box
                        sx={{
                            background: "white", padding:"5px 25px",
                            display:"flex", justifyContent:"space-between", alignItems:"center"}}
                    >
                        <img src="/Logo-issho.svg" width="170px" alt="Logo Issho" />
                        
                      
                     {/*    <img src="/sage_logo.png" alt="Logo Sage"  width="160px" /> */}
                    </Box>
                    <Card>
                        <CardContent>
                        <Box
                                sx={{
                                    px:2,
                                    py:1,
                                    boxShadow:3,
                                    borderRadius:5,
                                    color: "#fff",
                                    backgroundColor: "#fff",
                                    display:"flex",
                                    flexDirection: "row",
                                    /* flexGrow:1, */
                                    alignItems: "center",
                                    justifyContent:"space-between"
                                }}
                            >
                                    <Box>
                                        <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", flexWrap:"wrap"}}>
                                        
                                            <Typography 
                                                variant="body1"
                                                sx={{
                                                    color: Colors.DARK_BLUE,
                                                    fontSize: "18px",
                                                    fontWeight:"bold"
                                                }}
                                            
                                            >
                                                Votre moyenne générale
                                            </Typography>
                                            {/* <FormButton 
                                                
                                                size="small" 
                                                sx={{ minWidth:"100px", backgroundColor: Colors.GREEN}} 
                                                onClick={()=>dispatch(showSnackbar("Veuillez attendre la finalisation du job puis réessayer","error"))}
                                            >
                                                {false == true ? <CircularProgress size={20} thickness={5} sx={{ color: "#fff" }} /> : null}
                                                Comparer avec la moyenne
                                            </FormButton> */}
                                        </Box>
                                        <Typography 
                                            variant="body1"
                                            sx={{
                                                color: Colors.TITLE_2,
                                                fontSize: "14px",
                                                fontWeight:"500"
                                            }}
                                        
                                        >
                                            {localResult.recommandation}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                            alignItems: "center",
                                            flexDirection:"column"
                                        
                                        }}
                                    >

                                        <Gauge
                                            width={150} 
                                            height={110} 
                                            value={localResult?.moyenne ?? 0} // Use localResult?.moyenne as the current value
                                            ticks={[1, 2, 3, 4,5,6]} // Set the ticks to the desired values
                                            valueMin={1} // Set the minimum value of the gauge
                                            valueMax={6} 
                                            startAngle={-90} 
                                            endAngle={90} 
                                            text={
                                                ({ value, valueMax }) => `${value} / ${valueMax}`
                                            }
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 20,
                                                color: Colors.GREEN,
                                                transform: 'translate(0px, 0px)',
                                                }, [`& .${gaugeClasses.valueArc}`]: {
                                                    fill:  getColor(localResult?.moyenne ?? 0, true)
                                                    ,
                                                },
                                            }}
                                        
                                        />
                                      
                                    </Box>
                                  
                        </Box>
                        {/*  <Typography 
                                variant="h3"
                                sx={{
                                    color: Colors.LIGHT_BLUE,
                                    fontSize: "20px",
                                    display:"flex",
                                    gap:"5px",
                                    alignItems:"center",
                                }}
                            >
                                <RadarOutlined/>
                                Synthèse générale
                            </Typography> */}
                           
                            <Grid container spacing={2} sx={{mt:2}}>
                                <Grid item xs={12} md={6} sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"start",
                                    flexDirection:"column",
                                    marginTop:"8px"
                                }}>
                                    <Typography 
                                        variant="h5"
                                        sx={{
                                            color: Colors.DARK_BLUE,
                                            fontSize: "18px",
                                            fontWeight:"bold"
                                        }}
                                    >
                                        Répartition du score
                                    </Typography>
                                    <GlobalRadarChartComponent sectionScores={localResult.section_scores} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box 
                                        sx={{
                                            display:"flex", 
                                            flexDirection:"column",
                                            justifyContent:"center"
                                    }}>

                                        <Box
                                                sx={{
                                                    p:1,
                                                    border: "solid 1px "+ Colors.MEDIUM_GREY,
                                                    borderRadius: "16px"
                                                }}
                                        >
                                                <Typography 
                                                    variant="h5" 
                                                    sx={{
                                                        ml:2,
                                                        color: Colors.DARK_BLUE,
                                                        fontSize: "18px",
                                                        fontWeight:"bold"
                                                    }}
                                                >
                                                    Score par Section
                                                </Typography>
                                                <ScrollingBox 
                                                    sx={{
                                                        mt:1,
                                                        /* height:"260px",
                                                        overflowY: "auto", */
                                                        }}
                                                >
                                                    <List 
                                                        sx={{
                                                            p:0,
                                                            width: '100%',
                                                            bgcolor: 'background.paper' 
                                                        }}
                                                    >
                                                        {
                                                            localResult.section_scores.map((section, index)=>
                                                                        <Box>
                                                                            <ListItem 
                                                                                key={section.id}
                                                                                onClick={() => handleScrollToSection(index)} // Handle click to scroll
                                                                                sx={{
                                                                                    display:"flex",
                                                                                    justifyContent:"space-between",
                                                                                    alignItems:"center",
                                                                                    py:"0px"
                                                                                }}
                                                                            >
                                                                                <ListItemText 
                                                                                    primary={section.name} 
                                                                                    secondary={"Section "+(index+1)+" - "+ (section.isAnswered ? "Commencée" : "Pas encore commencée")} 
                                                                                    primaryTypographyProps={{
                                                                                        sx: {
                                                                                        fontWeight: 'bold',
                                                                                        fontSize: '14px', 
                                                                                        color: Colors.LIGHT_BLUE, 
                                                                                        lineHeight:1.2
                                                                                        }
                                                                                    }}   
                                                                                    secondaryTypographyProps={{
                                                                                        sx: {
                                                                                        fontSize: '12px', 
                                                                                        color: Colors.GREEN, 
                                                                                        lineHeight:1.2
                                                                                        }
                                                                                    }}    
                                                                                />
                                                                            
                                                                                <Chip 
                                                                                    sx={{
                                                                                        backgroundColor: getColor(Math.round((section.score / section.total_questions) * 100) / 100, section.isAnswered),
                                                                                        
                                    
                                                                                        color: "white", // Text color
                                                                                        boxShadow: 3,
                                                                                        minWidth:"100px",
                                                                                        py:"2px",
                                                                                        
                                                                                    }}
                                                                                    
                                                                                    label={
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize:"12px",
                                                                                                fontWeight:"bold"
                                                                                            }}
                                                                                        >
                                                                                            Score <span style={{fontSize:"14px"}}>{Math.round((section.score / section.total_questions) * 100) / 100}</span>
                                                                                        </Typography>
                                                                                    }
                                                                                />
                                                                            
                                                                            </ListItem>
                                                                            <Divider 
                                                                                
                                                                                sx={{
                                                                                    mx:2,
                                                                                    // Thickness of the divider
                                                                                    backgroundColor: Colors.MEDIUM_GREY, // Color of the divider
                                                                                }}
                                                                            />
                                                                            {/*   <Chip label="Détail" size="small" /> */}
                                                                        {/*  </Divider> */}
                                                                        </Box>
                                                                        
                                                                
                                                            )
                                                        }
                                                    </List>
                                                </ScrollingBox>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>


                <Card 
                    variant="outlined" 
                    sx={{
                        mt:2,
                        backgroundColor:"white"
                    }}
                >
                    <CardContent
                        sx={{
                            backgroundColor:"white"
                        }}
                    >
                        <Typography 
                            variant="h3"
                            sx={{
                                color: Colors.LIGHT_BLUE,
                                fontSize: "20px",

                            }}
                        >
                            Analyse détaillée & Recommandations
                        </Typography>
                        <Box sx={{ mt: 2, px:2 }}>
                            {localResult.advices.map((advice, sectionIndex) => (
                                <Box key={advice.id} sx={{ p:2, mb: 4, borderRadius: "12px", border: "solid 1px #d6d6d6" }}> {/* Added margin-bottom to separate sections */}
                                 
                                        <Typography variant="body1" sx={{mb:2}}>
                                            <span style={{color: Colors.DARK_BLUE, fontWeight:"bold"}}>Axe </span>:  {advice.axe}
                                        </Typography>
                                        <Typography variant="body1" sx={{mb:2}}>
                                        <span style={{color: Colors.DARK_BLUE, fontWeight:"bold"}}>Question clé </span> :  {advice.question}
                                        </Typography>
                                        <Typography variant="body1">
                                        <span style={{color: Colors.DARK_BLUE, fontWeight:"bold"}}>Illustration </span> :  {advice.illustration}
                                        </Typography>
                              
                                </Box>
                            ))}
                        </Box>
                                   
                    </CardContent>
                </Card>
            </Box>
           
        )}

      
      
        </ScrollingDialogContent>
       {/*  <DialogActions
          sx={{ px: 2, py: 1 }}
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
            <FormButtonCancel onClick={onSubmit} disabled={loading}>
              {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : "Confirmer l'affectation"}
            </FormButtonCancel>
        </DialogActions> */}
      </Dialog>
    );
};
  

function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  
    if (valueAngle === null) {
      // No value to display
      return null;
    }
  
    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="#3FB4B1" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="#3FB4B1"
          strokeWidth={3}
        />
      </g>
    );
  }
  export default ResultsModal;